<template>
  <div id="listPageId">
    <template>
      <slot name="overlay"></slot>
    </template>
    <v-data-table
        v-model="selected"
        :headers="headers"
        :items="data"
        :footer-props="{ 'items-per-page-options': [...limitList] }"
        :options.sync="pagination"
        :server-items-length="total"
        :sort-by="sort"
        :sort-desc="descending"
        :class="elevation"
        :show-select="showSelected"
        @input="selectedItemHandler"
    >
      <template v-slot:top>
        <slot name="top"></slot>
      </template>
      <!-- eslint-disable-next-line  -->
      <template v-for="column in dynamicColumns" v-slot:[`item.${column.name}`]="{ item }">
        <template v-if="column.name === 'is_active'">
          <g-switch
              v-if="!item.isDeleted"
              :current-item="item"
              @switchHandler="switchHandler"
          />
          <g-status-chip v-else text="Deleted" color="error"/>
        </template>
        <template v-else>
          {{ column.callback(item, column.name) }}
        </template>
      </template>
      <!-- eslint-disable-next-line  -->
      <template v-slot:item.isActive="{ item }">
        <g-switch
            v-if="!item.isDeleted"
            :current-item="item"
            @switchHandler="switchHandler"
        />
        <g-status-chip v-else text="Deleted" color="error"/>
      </template>
      <template v-slot:item.isWPAccess="{ item }">
        <w-p-access-switch-component
            :current-item="item"
            @switchHandler="wpAccessHandler"
        />
      </template>

      <!-- eslint-disable-next-line  -->
      <template v-slot:item.isDeleted="{ item }" class="justify-center">
        <g-status-chip v-if="item.isDeleted" text="Deleted" color="error"/>
        <g-status-chip v-else-if="item.isBlocked" text="Blocked" color="info"/>
      </template>

      <template v-slot:item.wpToken="{ item }">
        <g-payment-status-chip v-if="item.isSkipped" text="Skipped" color="error"/>
        <g-payment-status-chip v-else-if="item.wpToken" text="Yes" color="deep-purple darken-3"/>
        <g-payment-status-chip v-else text="No" color="red darken-2"/>
      </template>

      <template v-slot:item.attachment="{ item }">
        <g-avatar v-if="item.attachment || item.methods.attachment " :source="item.methods.attachment"/>
        <g-avatar v-else/>
      </template>

      <template v-slot:item.paymentStatus="{ item }">
        <g-payment-status-chip v-if="item.isDeleted && item.paymentStatus === 900"
                               :text="getDeleteStatus(item.billingType,'Removed')" color="indigo"/>
        <g-payment-status-chip v-else-if="item.isDeleted" :text="getDeleteStatus(item.billingType,'Deleted')"
                               color="blue"/>
        <template v-else-if="item.paymentStatus === 100">
          <g-payment-status-chip/>
          <v-chip class="mb-2" color="green" text-color="white" x-small v-if="getDueStatus(item.createdAt)">
            {{ getDueStatus(item.createdAt) }}
          </v-chip>
        </template>
        <g-payment-status-chip v-else-if="item.paymentStatus === 200" color="green" text="Paid"/>
        <g-payment-status-chip v-else-if="item.paymentStatus === 300" color="lime" text="Pending"/>
        <g-payment-status-chip v-else-if="item.paymentStatus === 400" color="green darken-4" text="Refund"/>
        <g-payment-status-chip v-else-if="item.paymentStatus === 600" color="orange" text="Republish"/>
      </template>
      <template v-slot:item.status="{ item }">
        <g-payment-status-chip v-if="item.status === 200" color="green" text="Paid"/>
        <g-payment-status-chip v-else-if="item.status === 300" color="lime" text="Pending"/>
        <g-payment-status-chip v-else-if="item.status === 400" color="green darken-4" text="Refund"/>
        <g-payment-status-chip v-else-if="item.status === 600" color="orange" text="Republish"/>
      </template>
      <template v-slot:item.status_code="{ item }">
        <g-payment-status-chip :outlined="true" text-color="red" :text="item.status_code"/>
      </template>
      <template v-slot:item.liveLink="{item}">
        {{ item.liveLink }}
        <chip-button
            text="click"
            css-class="pt-2 pb-2"
            color="green"
            @click.stop
            @onClickHandler="linkHandler(item)"
        />
      </template>
      <template v-slot:item.links="{ item }">
        <chip-button
            text="Copy"
            :x-small="false"
            css-class="pt-2 pb-2"
            color="green"
            @click.stop
            @onClickHandler="copyHandler(getLink(item))"
        />
      </template>
      <template v-slot:item.refLink="{ item }">
        <chip-button
            text="Copy"
            :x-small="false"
            css-class="pt-2 pb-2"
            color="green"
            @click.stop
            @onClickHandler="copyHandler(getRefLink(item.refcode))"
        />
      </template>
      <template v-slot:item.site.site_url="{item}">
        <chip-button
            :text="item.site.site_url"
            :x-small="false"
            css-class="pt-2 pb-2"
            color="primary"
            @click.stop
            @onClickHandler="linkSiteHandler(item.site.site_url)"
        />
      </template>
      <template v-slot:item.site_url="{item}">
        <chip-button
            :text="item.site_url"
            :x-small="false"
            css-class="pt-2 pb-2"
            color="primary"
            @click.stop
            @onClickHandler="linkSiteHandler(item.site_url)"
        />
      </template>
      <template v-slot:item.isPrepayment="{ item }">
        <g-payment-status-chip v-if="item.isPrepayment" :text="prepaymentText(item.isPrepayment)"
                               color="deep-purple darken-3"/>
      </template>
      <template v-slot:item.email="{ item }">
        <span class="client-type" v-if="item.isNew">New&nbsp;</span>&nbsp;{{ item.email }}
        <v-icon color="orange" title="Special Client" v-if="item.clientType === 3">mdi-shield-star</v-icon>
        <v-icon color="primary" title="Fixed Client" v-else-if="item.clientType === 2">mdi-shield-account-outline
        </v-icon>
        <v-icon color="primary" title="Agency Client" v-else-if="item.clientType === 5">mdi-alpha-a-circle</v-icon>
        <img title="More Special Client" width="20" :src="require('../../assets/img/double-star.png')"
             v-else-if="item.clientType === 4">
        <v-icon color="primary" title="Buyer" v-else-if="item.clientType === 6">mdi-alpha-b-circle</v-icon>
      </template>

      <template v-slot:item.google_news="{ item }">
        {{ getItemStatus(item.google_news) }}
      </template>
      <template v-slot:item.casino="{ item }">
        {{ getItemStatus(item.casino) }}
      </template>
      <template v-slot:item.follow="{ item }">
        {{ getItemStatus(item.follow) }}
      </template>
      <template v-slot:item.ahrefs="{item}">
        <v-chip
            class="ma-2"
            color="green"
            text-color="white"
            link
            @click.prevent.stop="ahrefHandler(item.siteUrl)"
        >
          Click
        </v-chip>
      </template>
      <template v-slot:item.isCheckBrokenLink="{ item }">
        <switch-broken-component :current-item="item" @switchHandler="switchBrokenHandler"/>
      </template>
      <template v-slot:item.billingType="{ item }">
        {{item.billingType}}
        <v-tooltip top :color="setLinkExpiryColor(item.linkExpiryDate)" v-if="item.billingType === 'Home Page Link Insertion on'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon :color="setLinkExpiryColor(item.linkExpiryDate)" v-bind="attrs" v-on="on">mdi-link
            </v-icon>
          </template>
          <span>Link Expired on: {{item.linkExpiryDate ? getDate(item, 'linkExpiryDate') : 'Unlimited'}}</span>
        </v-tooltip>
      </template>
      <!-- eslint-disable-next-line  -->
      <template v-slot:item.actions="{ item }">
        <row-action-menu-button
            :action-columns="defaultActionButtons"
            :column="item"
            :row="item"
            :list-group="listGroup"
            @actionButtonClick="handleActionButtonClick"
        />
      </template>
      <template v-slot:[`footer.prepend`] v-if="showTotal">
        <v-subheader v-if="user && (user.role_id === 1 || permissionHandler(user.email))">Grand Total:
          &nbsp;<strong>${{ grandTotal }} USD</strong></v-subheader>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import RowActionMenuButton from "../../components/RowActionMenuButton";
import {mapState} from "vuex";
import GSwitch from "../../components/list/GSwitch";
import GStatusChip from "../../components/list/GStatusChip";
import {countries} from "country-list-json";
import GPaymentStatusChip from "../GPaymentStatusChip";
import moment from "moment";
import GAvatar from "../GAvatar";
import ChipButton from "../buttons/ChipButton";
import SwitchBrokenComponent from "../SwitchBrokenComponent";
import pTable from "../../json/pTable.json";
import WPAccessSwitchComponent from "../WPAccessSwitchComponent";

export default {
  name: "GListPage",
  components: {
    WPAccessSwitchComponent,
    SwitchBrokenComponent,
    ChipButton,
    GAvatar,
    GPaymentStatusChip,
    GStatusChip,
    GSwitch,
    RowActionMenuButton
  },
  data() {
    return {
      grandTotal: 0,
      selected: [],
      limitList: [5, 10, 20, 50, 100],
      pagination: {
        itemsPerPage: 20,
      },
      total: 0,
      defaultActionButtons: [
        {
          category: "edit",
          icon: "mdi-pencil",
          color: "",
          text: "Edit",
          clickHandler: this.editItem,
        },
        {
          category: "delete",
          icon: "mdi-delete",
          color: "error",
          text: "Delete",
          clickHandler: this.deleteItem,
        },
        {
          category: "destroy",
          icon: "mdi-delete-forever",
          color: "error",
          text: "Delete Forever",
          clickHandler: this.destroyItem,
        },
        {
          category: "restored",
          icon: "mdi-backup-restore",
          color: "primary",
          text: "Restore",
          clickHandler: this.restoreItem,
        },
      ],
    };
  },
  created() {
    if (this.actionButtons && this.actionButtons.length) {
      const actionButtons = this.actionButtons.map((button) => {
        if (button.clickHandler) {
          button.clickHandler = this[button.clickHandler];
        }
        return button;
      });

      this.defaultActionButtons = this.showDefaultActionButtons
          ? this.defaultActionButtons.concat(actionButtons)
          : [...actionButtons];
    }
  },
  props: {
    elevation: {
      type: String,
      default: () => "elevation-1",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    stateendpoint: {
      type: String,
      default: function () {
        return "";
      },
    },
    descending: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    sort: {
      type: String,
      default: function () {
        return "name";
      },
    },
    showDefaultActionButtons: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    actionButtons: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showSelected: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    showTotal: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    listGroup: {
      type: String,
      default: ''
    },
    paymentStatus: {
      type: [String, Number],
      default: ''
    }
  },
/*  mounted() {
    this.loadData();
  },*/
  watch: {
    pagination: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      data: function (state) {
        const stateData = this.stateendpoint
            .split(".")
            .reduce((p, c) => (p && p[c]) || null, state);

        this.total = stateData.total;
        this.grandTotal = stateData.grandTotal ? stateData.grandTotal : 0;
        return stateData.data;
      },
      user: (state) => state.auth.user,
    }),
    dynamicColumns() {
      return this.columns.map((item) => {
        return {
          name: item.name,
          callback: this[item.callback],
        };
      });
    },
  },
  methods: {
    loadData(isPagingReset = false) {
      this.isOverlay = true;
      let paging = this.getPaging();

      this.$emit("loadData", {paging, isPagingReset});
    },
    handleActionButtonClick(data) {
      const actionButton = this.defaultActionButtons.find(
          (button) => button.category === data.category
      );

      actionButton.clickHandler(data.row);
    },
    editItem(item) {
      this.$emit("editItem", item);
    },
    editItemNewPage(item) {
      this.$emit("editItemNewPage", item);
    },
    invoiceMailDetailsHandler(item) {
      this.$emit("invoiceMailDetailsHandler", item);
    },
    invoiceDetailsHandler(item) {
      this.$emit("invoiceDetailsHandler", item);
    },
    deleteItem(item) {
      this.$emit("deleteItem", item);
    },
    destroyItem(item) {
      this.$emit("destroyItem", item);
    },
    restoreItem(item) {
      this.$emit("restoreItem", item);
    },
    republishedMailHandler(item) {
      this.$emit("republishedMailHandler", item);
    },
    removePostItem(item) {
      this.$emit("removePostItem", item);
    },
    deleteWithoutItem(item) {
      this.$emit("deleteWithoutItem", item);
    },
    counterLinkHandler(item) {
      this.$emit("counterLinkHandler", item);
    },
    invoiceLinkHandler(item) {
      this.$emit("invoiceLinkHandler", item);
    },
    salesDetailsHandler(item) {
      this.$emit("salesDetailsHandler", item);
    },
    republishHandler(item) {
      this.$emit("republishHandler", item);
    },
    liveLinkReplacementHandler(item) {
      this.$emit("liveLinkReplacementHandler", item);
    },
    onChangedPrepaymentStatus(item) {
      this.$emit("onChangedPrepaymentStatus", item);
    },
    switchHandler({item, eventStatus}) {
      this.$emit("switchHandler", {item, eventStatus});
    },
    wpAccessHandler({item, eventStatus}) {
      this.$emit("wpAccessHandler", {item, eventStatus});
    },
    resetItem(item) {
      this.$emit("resetItem", item);
    },
    resendPaymentMailHandler(item) {
      this.$emit("resendPaymentMailHandler", item);
    },
    reminderPaymentMailHandler(item) {
      this.$emit("reminderPaymentMailHandler", item);
    },
    afterReminderPaymentMailHandler(item) {
      this.$emit("afterReminderPaymentMailHandler", item);
    },
    makePaymentHandler(item) {
      this.$emit("makePaymentHandler", item);
    },
    refundInvoiceHandler(item) {
      this.$emit("refundInvoiceHandler", item);
    },
    switchBrokenHandler(item) {
      this.$emit("switchBrokenHandler", item);
    },
    getPaging() {
      let {page, itemsPerPage, sortBy, sortDesc} = this.pagination;
      return {
        page,
        rowsPerPage: itemsPerPage,
        sortBy: sortBy[0],
        descending: sortDesc[0],
        s: "",
      };
    },
    selectedItemHandler() {
      this.$emit("selectedItemHandler", this.selected)
    },
    getMoneySign(item, column) {
      return `$${column.split('.').reduce((p, c) => p && p[c] || 0, item)}`;
    },
    getPercentageSign(item, column) {
      return `${column.split('.').reduce((p, c) => p && p[c] || 0, item)}%`;
    },
    getCountryName(item, column) {
      let code = column.split('.').reduce((p, c) => p && p[c] || null, item);
      const country = countries.find((country) => country.code === code)
      if (country) {
        return country.name;
      }
      return "";
    },
    getDeleteStatus(type, text) {
      if (!type) {
        return text;
      } else if (type === "Link Insertion on" || type === "Home Page Link Insertion on") {
        return 'Link Insert ' + text;
      } else {
        return text;
      }
    },
    getDueStatus(dateTime) {
      let days = moment().diff(moment(dateTime), 'days');
      if (days > 4 && days < 7) {
        return 'Late Due';
      } else if (days > 7) {
        return 'Over Due';
      } else {
        return '';
      }
    },
    linkHandler(item) {
      let url = item.liveLink.replace(/[\u200B-\u200D\uFEFF]/g, '');
      window.open(url, '_blank').focus();
    },
    linkSiteHandler(link) {
      let url = link.replace(/[\u200B-\u200D\uFEFF]/g, '');
      window.open(`http://${url}`, '_blank').focus();
    },
    getDate(item, column) {
      if (item[column]) return moment(item[column], moment.ISO_8601).format('MMM D,  YYYY');
      return '';
    },
    getLink(item) {
      if (!item) return;
      let {errorType, logs} = item;
      if(errorType === 5){
        return (logs.split(",")[logs.split(",").length - 1]).replace("Live Link:", "").trim();
      }

      return (logs.split(",")[1]).replace("Live Link:", "").trim();
    },
    copyHandler(linkUrl) {
      this.copyClipboard(linkUrl);
    },
    copyClipboard(text) {
      const me = this;
      this.$copyText(text).then(function (e) {
        me.setSnackBar({text: 'Clipboard Copied!', type: 'info'})
      }, function (e) {
        me.setSnackBar({text: 'Can not Copied!', type: 'error'})
      })
    },
    prepaymentText(value) {
      if (value === 1) {
        return 'Prepaid';
      } else if (value === 2) {
        return 'Completed';
      } else {
        return '';
      }
    },
    getRefLink(refcode) {
      const api = process.env.VUE_APP_BASE_URL;
      return `${api}/direct-payment?refno=${refcode}`;
    },
    setSnackBar({text, type}) {
      this.$store.commit("SET_SNACKBAR", {text, type});
    },
    getItemStatus(status) {
      if (status === 1) {
        return 'Yes';
      }
      return 'No';
    },
    ahrefHandler(siteUrl) {
      if (!siteUrl) return;
      window.open(`https://app.ahrefs.com/site-explorer/overview/v2/subdomains/live?target=${siteUrl}`, '_blank');
    },
    permissionHandler(email) {
      if(pTable.invoiceGrandTotal.find(item => item === email)) {
        return true;
      }

      if(this.paymentStatus === 100){
        return pTable.dueTotal.find(item => item === email);
      }

      return false;
    },
    setLinkExpiryColor(date) {
      if (date) return moment(date).isSameOrAfter(moment().format('YYYY-MM-DD')) ? 'primary' : 'error';
      else return 'primary'
    },
  },
};
</script>

<style scoped lang="scss">
.client-type {
  display: inline-block;
  background-color: #7048e8;
  color: #fff;
  padding: 4px 8px;
  font-size: 10px;
  border-radius: 50px;
}
</style>
