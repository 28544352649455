<template>
  <v-switch
    :input-value="currentItem.isActive || currentItem.status || currentItem.is_active"
    color="primary"
    dense
    hide-details
    inset
    style="margin-top: 0; padding-top: 0"
    @change="switchHandler(currentItem, $event)"
  />
</template>

<script>
export default {
  name: "GSwitch",
  props: {
    currentItem: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    switchHandler(item, eventStatus) {
      this.$emit("switchHandler", { item, eventStatus });
    },
  },
};
</script>

<style scoped></style>
