<template>
  <v-chip
      :class="cssClass"
      :color="color"
      :x-small="xSmall"
      text-color="white"
      link
      @click.prevent.stop="onClickHandler"
  >
    {{text}}
  </v-chip>
</template>

<script>
export default {
  name: "ChipButton",
  props: {
    xSmall: {
      type: Boolean,
      default: () => true
    },
    cssClass: {
      type: String,
      default: () => 'ml-1 mr-1'
    },
    color: {
      type: String,
      default: () => 'primary'
    },
    text: {
      type: String,
      default: () => ''
    },
    item: {
      type: [String, Object],
      default: () => ''
    }
  },
  methods: {
    onClickHandler() {
      this.$emit('onClickHandler', this.item)
    }
  }
}
</script>

<style scoped>

</style>