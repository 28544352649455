<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <g-card page-title="Shanto IT Invoices" page-icon="mdi-equal-box">
    <g-list-page
        :columns="columns"
        :headers="headers"
        :actions="actions"
        :show-default-action-buttons="false"
        stateendpoint="shantoitInvoice.shantoitInvoices"
        sort="invoiceId"
        @loadData="loadData"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(invoice id, email)"
                @applySearch="handleSearch"
            />
          </v-col>
        </v-row>
        <v-row>
          <g-date-selector
              class="ma-2 ml-6"
              @onChangeDateHandler="calenderHandler"
          />
          <v-select
              dense
              outlined
              class="ma-2 ml-6"
              :items="payStatusItems"
              v-model="currentPaging.paymentStatus"
              label="Select a payment status"
              style="max-width: 250px"
              @change="loadData({isPagingReset: true})"
          />
        </v-row>
      </template>
    </g-list-page>
  </g-card>
</template>
<script>
import ListMixins from "../components/mixins/list/List";
import GCard from "../components/GCard";
import GListPage from "../components/list/GListPage";
import PageOverlay from "../components/PageOverlay";
import GPSearch from "../components/GPSearch";
import GDateSelector from "../components/calendar/GDateSelector";

export default {
  name: 'ShantoITInvoices',
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard - Shanto IT Invoices | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {
    GDateSelector,
    GPSearch,
    PageOverlay,
    GListPage,
    GCard
  },
  mixins: [ListMixins],
  created() {
    this.currentPaging.paymentStatus = "";
  },
  data() {
    return {
      headers: [
        {
          text: 'Invoice No',
          value: 'invoiceId'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Payment Status',
          value: 'paymentStatus'
        },
        {
          text: 'Created Date',
          value: 'created_at'
        },
        {
          text: 'Payment Date',
          value: 'paymentDate'
        },
        {
          text: 'Total Amount',
          value: 'total',
          align: 'right'
        },
        {
          text: 'Payment Fees',
          value: 'paymentFees',
          align: 'right'
        },
        {
          text: 'Gross Total',
          value: 'grossTotal',
          align: 'right'
        }
      ],
      columns: [
        {
          name: "total",
          callback: "getMoneySign"
        },
        {
          name: "paymentFees",
          callback: "getMoneySign"
        },
        {
          name: "grossTotal",
          callback: "getMoneySign"
        },
        {
          name: "created_at",
          callback: "getDate"
        },
        {
          name: "paymentDate",
          callback: "getDate"
        },
      ],
      actions: {
        load: "shantoitInvoice/load"
      },
      payStatusItems: [
        { text: "All", value: "" },
        { text: "Paid", value: 200 },
        { text: "Unpaid", value: 100 },
      ]
    }
  }
}
</script>
