<template>
  <base-outlined-button
    :text="text"
    color="#E57373"
    :icon="icon"
    :disabled="isDisabled"
    :loading="loading"
    :toolbar="toolbar"
    :small="small"
    @onButtonClick="onButtonClick"
  />
</template>

<script>
  import ButtonMixin from '../mixins/Button';
  export default {
    name: "CancelButton",
    mixins: [ButtonMixin],
    props: {
      text: {
          type: String,
          default: function () {
            return 'Cancel';
          }
      },
      icon: {
        type: String,
        default: "mdi-undo"
      }
    }
  }
</script>
