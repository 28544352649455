<template>
  <v-row class="mt-2 mb-0 ml-0 mr-0">
    <v-col :md="rowMd" :offset-md="offsetMd">
      <v-card :flat="showPageTitle" :elevation="elevation" tile light>
        <g-page-title v-if="showPageTitle" :icon="pageIcon" :title="pageTitle" :back-button="backButton"/>
        <v-card-text>
          <slot></slot>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import GPageTitle from "../components/GPageTitle";
export default {
  name: "GCard",
  components: { GPageTitle },
  props: {
    rowMd: {
      type: Number,
      default: () => {
        return 12;
      },
    },
    offsetMd: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    showPageTitle: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    elevation: {
      type: Number,
      default: () => {
        return 2;
      },
    },
    pageTitle: {
      type: String,
      default: "",
    },
    pageIcon: {
      type: String,
      default: "",
    },
    backButton: {
      type: Object,
      default: function () {
        return null;
      }
    }
  },
};
</script>

<style scoped></style>
