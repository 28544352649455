import moment from "moment/moment";

export default {
    data: () => ({
        customDate: [
            new Date().toISOString().substr(0, 7),
            new Date().toISOString().substr(0, 7)
        ],
        customMonth: new Date().toISOString().substr(0, 7),
        menu: false,
        step: 1
    }),
    props: {
        format: {
            type: String,
            default: function () {
                return 'DD/MM/YYYY';
            }
        },
        options: {
            type: Array,
            default: function () {
                return [
                    {
                        text: 'Today',
                        value: 'today'
                    },
                    {
                        text: 'Yesterday',
                        value: 'yesterday'
                    },
                    {
                        text: 'This Week',
                        value: 'this_week'
                    },
                    {
                        text: 'Last Week',
                        value: 'last_week'
                    },
                    {
                        text: 'Last 7 Days',
                        value: 'last_seven_days'
                    },
                    {
                        text: 'This Month',
                        value: 'this_month'
                    },
                    {
                        text: 'Last Month',
                        value: 'last_month'
                    },
                    {
                        text: 'Last 3 Months',
                        value: 'last_three_months'
                    },
                    {
                        text: 'This 3 Months',
                        value: 'this_three_months'
                    },
                    {
                        text: 'Last Year',
                        value: 'last_year'
                    },
                    {
                        text: 'This Year',
                        value: 'this_year'
                    },
                    {
                        text: 'Custom Range',
                        value: 'custom'
                    },
                    {
                        text: 'Custom Month',
                        value: 'custom_month'
                    },
                    {
                        text: 'Reset Calendar',
                        value: 'reset_calendar'
                    }
                ];
            }
        },
        maxWidth: {
            type: String,
            default: function () {
                return '100%';
            }
        },
        large: {
            type: Boolean,
            default: function () {
                return false;
            }
        },
        value: {
            type: String,
            default: function () {
                return ''
            }
        }
    },
    watch: {
        menu: function (show) {
            if (!show) {
                this.step = 1;
            }
        },
        selectedDateRange: {
            handler: function (newSelection) {
                this.$emit('onChangeDateHandler', newSelection);
            },
            deep: true
        }
    },
    methods: {
        onDateTypeSelect(item) {
            if (item.value === 'custom') {
                this.step = 2;
            } else if (item.value === 'custom_month') {
                this.step = 3;
            } else {
                this.selectedDateRangeType = item.text;
                this.selectedDateRange = this.selectedDataHandler(item.value);
                this.menu = false;
            }
        },
        onCustomDateTypeSelect(_customDate) {
            const startDate = moment(_customDate[0]).startOf('days');
            const endDate = moment(_customDate[1]).endOf('days');
            this.selectedDateRange = {
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD')
            };
            this.$refs.menu.save(_customDate);
            this.selectedDateRangeType = `${startDate.format(this.format)} To ${endDate.format(this.format)}`;
        },
        onCustomMonthTypeSelect(_customMonth){
            const startDate = moment(_customMonth).startOf('month');
            const endDate = moment(_customMonth).endOf('month');

            this.selectedDateRange = {
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD')
            };
            this.$refs.menu.save(_customMonth);
            this.selectedDateRangeType = startDate.format('YYYY, MMMM');
        },
        selectedDataHandler(dateType) {
            let startDate = moment();
            let endDate = moment();

            switch (dateType) {
                case 'today':
                    startDate = moment().startOf('days').format('YYYY-MM-DD');
                    endDate = moment().endOf('days').format('YYYY-MM-DD');
                    break;
                case 'yesterday':
                    startDate = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
                    endDate = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD');
                    break;
                case 'this_week':
                    startDate = moment().startOf('isoweek').format('YYYY-MM-DD');
                    endDate = moment().endOf('isoweek').format('YYYY-MM-DD');
                    break;
                case 'last_week':
                    startDate = moment().subtract(1, 'week').startOf('isoweek').format('YYYY-MM-DD');
                    endDate = moment().subtract(1, 'week').endOf('isoweek').format('YYYY-MM-DD');
                    break;
                case 'last_seven_days':
                    startDate = moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD');
                    endDate = moment().endOf('days').format('YYYY-MM-DD');
                    break;
                case 'this_month':
                    startDate = moment().startOf('month').format('YYYY-MM-DD');
                    endDate = moment().endOf('month').format('YYYY-MM-DD');
                    break;
                case 'last_month':
                    startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
                    endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
                    break;
                case 'this_three_months':
                    startDate = moment().subtract(2, 'month').startOf('month').format('YYYY-MM-DD');
                    endDate = moment().endOf('month').format('YYYY-MM-DD');
                    break;
                case 'last_three_months':
                    startDate = moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD');
                    endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
                    break;
                case 'last_year':
                    startDate = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
                    endDate = moment().subtract(1,'year').endOf('year').format('YYYY-MM-DD');
                    break;
                case 'this_year':
                    startDate = moment().startOf('year').format('YYYY-MM-DD');
                    endDate = moment().endOf('year').format('YYYY-MM-DD');
                    break;
                case 'reset_calendar':
                    startDate = '';
                    endDate = '';
                    this.selectedDateRange = '';
                    this.selectedDateRangeType = '';
                    break;
                default:
                    break;
            }

            return {
                startDate: startDate,
                endDate: endDate
            }
        }
    }
}