<template>
  <v-layout align-end justify-end>
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click.stop
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <div v-for="(actionColumn, key) in actionColumns" :key="key" v-if="handleColumn(actionColumn.category)">
          <v-list-item @click="onActionButtonClick(actionColumn.category)" :disabled="disabled(actionColumn, row)">
            <v-list-item-action>
              <v-icon :color="actionColumn.color || 'primary' ">{{actionColumn.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{actionColumn.text}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </v-layout>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: 'RowActionMenuButton',
    props: {
      disabled: {
        type: Function,
        default: function (actionColumn = {}, row = {}) {
          return false;
        }
      },
      row: {
        type: Object,
        default: function () {
          return {};
        },
      },
      actionColumns: {
        type: Array,
        default: function () {
          return [];
        }
      },
      column: {
        type:Object,
        default: () => ({})
      },
      /*user: {
        type:Object,
        default: () => ({})
      },*/
      showInvoiceButton: {
        type: Boolean,
        default: function () {
          return false;
        }
      },
      listGroup: {
        type: String,
        default: "",
      }
    },
    computed: {
      ...mapState({
        user: state => state.auth.user
      })
    },
    methods: {
      onActionButtonClick (category) {
        this.$emit('actionButtonClick', {
          row: this.row,
          category
        })
      },
      handleColumn(text) {
        if(text.toLowerCase() === 'prepaid') {
          return !(this.column.isPrepayment === 0)
        }

        if (text === 'manualCredit'){
          return this.getPermission3()
        }

        /*if(text.toLowerCase() === 'edit') {
          return this.user.email === 'admin@rabbiitfirm.com';
        }*/

        if(this.column.isDeleted) {
          return !(text.toLowerCase() === 'delete' || text.toLowerCase() === 'deletepermanent' || text.toLowerCase() === 'deletewithoutpost' || text.toLowerCase() === 'remove' || text.toLowerCase() === 'credit' || text.toLowerCase() === 'edit' || text.toLowerCase() === 'resendmail' || text.toLowerCase() === 'republish');
        } else if(this.column.paymentStatus === 200) {
          if(this.getPermission() && text.toLowerCase() === 'refund') return true;
          if(this.getPermission() && text.toLowerCase() === 'deletepermanent') return false;
          if(this.getPermission2() && (text.toLowerCase() === 'delete' || text.toLowerCase() === 'remove')) return false;
          if(this.user && this.user.role_id === 1 && text.toLowerCase() === 'edit') return true;
          return !(text.toLowerCase() === 'credit' || text.toLowerCase() === 'resendmail' || text.toLowerCase() === 'restore' || text.toLowerCase() === 'reset' || text.toLowerCase() === 'edit' || text.toLowerCase() === 'republish');
        } else if(this.column.paymentStatus === 100) {
          if(text.toLowerCase() === 'refund') return false;
          if(this.getPermission() && (text.toLowerCase() === 'deletepermanent' || text.toLowerCase() === 'credit')) return false;
          if(this.getPermission2() && (text.toLowerCase() === 'delete' || text.toLowerCase() === 'remove')) return false;
          return !(text.toLowerCase() === 'restore' || text.toLowerCase() === 'reset' || text.toLowerCase() === 'republish')
        }
        else if(this.column.paymentStatus === 300) {
          if(text.toLowerCase() === 'refund') return false;
          if(this.getPermission2() && (text.toLowerCase() === 'delete' || text.toLowerCase() === 'remove' )) return false;
          return !(text.toLowerCase() === 'deletepermanent' || text.toLowerCase() === 'republish' || text.toLowerCase() === 'edit');
        } else if(this.user && this.user.role_id !== 1 && text.toLowerCase() === 'deletepermanent' && !this.showInvoiceButton) {
          return false;
        } else if(this.getPermission() && text.toLowerCase() === 'deletepermanent' && this.showInvoiceButton) {
          return true;
        } else if(this.getPermission() && text.toLowerCase() === 'deletewithoutpost') {
          return true;
        }else if(text.toLowerCase() === 'refund') {
          return false;
        }

        return true;
      },
      getPermission() {
        return !(this.user && (this.user.email === 'admin@rabbiitfirm.com' || this.user.email === 'kamrul.1740@gmail.com' || this.user.email === 'rezaulbdbh@gmail.com' || this.user.email === 'manirhossain369@gmail.com'))
      },
      getPermission3() {
        return this.user && (this.user.email === 'admin@rabbiitfirm.com' || this.user.email === 'kamrul.1740@gmail.com' || this.user.email === 'rezaulbdbh@gmail.com')
      },
      getPermission2() {
        let permissionArr = [
                 'admin@rabbiitfirm.com',
                 'manirhossain369@gmail.com',
                 'kamrul.1740@gmail.com',
                 'rezaulbdbh@gmail.com',
                 'mojammelhosen007@gmail.com',
                 'mrmizan596@gmail.com',
                 'mizanrabbiit@gmail.com',
                 'hafizurbdbh@gmail.com',
                 'monjurul311@gmail.com',
                 'rmdmizanur605@gmail.com',
                 'sadikulbd23@gmail.com',
                 'hridoybdbh03@gmail.com',
        ];

        let email = this.user && this.user.email ? this.user.email : '';

        return !permissionArr.find((item) =>item === email);
      }
    }
  }
</script>

<style scoped>

</style>
