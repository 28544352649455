import { render, staticRenderFns } from "./SwitchBrokenComponent.vue?vue&type=template&id=65685318&scoped=true"
import script from "./SwitchBrokenComponent.vue?vue&type=script&lang=js"
export * from "./SwitchBrokenComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65685318",
  null
  
)

export default component.exports